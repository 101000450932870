<template>
  <div>
    <div class="page-header clear-filter" filter-color="primary">
      <parallax
          class="page-header-image">
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <img alt="Academy By Impezar" class="n-logo" src="img/academy.png"/>
            <h6 class="font-weight-lighter">IMPEZAR</h6>
            <h1 class="h1-seo font-weight-bold">Academy</h1>
          <h3 class="font-weight-light">Your Passport To The Future</h3>
        </div>
      </div>
    </div>

    <training></training>
    <corporate-training class="component-padding"></corporate-training>
    <plan-carousel id="plan_pack" class="component-padding"></plan-carousel>
    <who-we-are class="component-padding"></who-we-are>
    <contact-us class="component-padding"></contact-us>


    <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {Parallax} from '@/components';
import WhoWeAre from './components/WhoWeAre';
import Training from './components/Training';
import ContactUs from './components/ContactUs';
import PlanCarousel from './components/PlanCarousel.vue';
import CorporateTraining from "./components/CorporateTraining";


export default {
  data() {
    return {};
  },
  name: 'index',
  bodyClass: 'index-page',
  components: {
    CorporateTraining,
    Parallax,
    PlanCarousel,
    WhoWeAre,
    Training,
    ContactUs
  }
};
</script>
