<template>
  <div class="row">
    <div class="col-md-12">
      <h2 class="title text-center pb-3">Here is our team</h2>
      <div class="team">
        <div class="row">

          <div v-if="!teamArray || teamArray.length == 0 || teamArray.includes(4)" class="col-md-4 pt-5">
          <div class="team-player">
            <div class="d-flex justify-content-center">
              <avatar username="Ibin Issac" :size="100"></avatar>
            </div>
            <h4 class="title text-center">Ibin Issac</h4>
            <p class="category text-primary text-center">Software Architect</p>

            <read-more class="text-justify" more-str="read more" text="Ibin Issac founded Impezar with a vision of embracing technology to solve everyday problems in the lives of common man and making it accessible to everyone. He also dreams of raising a technically skilled generation who are better problem solvers and key decision makers by sowing the seeds of technology into their minds from a very young age. He has extensive experience in the software industry and has developed large-scale systems for tech giants. He is our guiding light and  drives us with his passion to give life to his dream."
                       link="#" less-str="read less" :max-chars="200"></read-more>
          </div>
        </div>
          <div v-if="!teamArray || teamArray.length == 0 || teamArray.includes(2)" class="col-md-4 pt-5">
            <div class="team-player">
              <div class="d-flex justify-content-center">
                <avatar username="Anu Jacob" :size="100"></avatar>
              </div>
              <h4 class="title text-center">Anu Jacob</h4>
              <p class="category text-primary text-center">Senior Back-End Engineer</p>
              <read-more class="text-justify" more-str="read more" text="Anu Jacob, our co-founder is associated with Impezar right from its inception. She is the strongest support and is actively involved in the building of our organisation. She has worked with leading software companies and love to share her knowledge with the world for a mighty purpose. She joins hands with Impezar in fulfilling our vision of building a technically skilled people for tomorrow’s world."
                         link="#" less-str="read less" :max-chars="200"></read-more>
            </div>
          </div>
          <div v-if="!teamArray || teamArray.length == 0 || teamArray.includes(1)" class="col-md-4 pt-5">
          <div class="team-player">
            <div class="d-flex justify-content-center">
              <avatar username="Aloshious Raju" :size="100"></avatar>
            </div>
            <h4 class="title text-center">Aloshious Raju</h4>
            <p class="category text-primary text-center">Front-End Developer</p>
            <read-more class="text-justify" more-str="read more" text="Aloshious Raju joined Impezar as an associate and is involved in product developments at our organisation. He also shares our dream of building a community with strong technical skills. With his solid knowledge base, he wish to inspire people to join hands with us to making our dream a reality."
                       link="#" less-str="read less" :max-chars="180"></read-more>
          </div>
        </div>
          <div v-if="!teamArray || teamArray.length == 0 || teamArray.includes(3)" class="col-md-4 pt-5">
            <div class="team-player">
              <div class="d-flex justify-content-center">
                <avatar username="Arun T" :size="100"></avatar>
              </div>
              <h4 class="title text-center">Arun T</h4>
              <p class="category text-primary text-center">DevOps Consultant</p>
              <read-more class="text-justify" more-str="read more" text="Arun T is involved in our product development and service projects as DevOps Evangelist.
               He implements and orchestrates DevOps across our organization and leads a team of DevOps engineers. He has a string of certifications which includes Red Hat Certified System Administrator,
                Red Hat Certified Engineer, Red Hat Certified Virtualization Administrator, Red Hat Certificate of Expertise in Ansible Automation, Red Hat Certified Specialist in Openshift Administration. We are privileged to have this talent in our team "
                         link="#" less-str="read less" :max-chars="200"></read-more>
            </div>
          </div>

          <div v-if="!teamArray || teamArray.length == 0 || teamArray.includes(5)" class="col-md-4 pt-5">
            <div class="team-player">
              <div class="d-flex justify-content-center">
                <avatar username="Stebin Berly" :size="100"></avatar>
              </div>
              <h4 class="title text-center">Stebin Berly</h4>
              <p class="category text-primary text-center">QA Engineer</p>

              <read-more class="text-justify" more-str="read more" text="Stebin Berly, again a proud alumni of Impezar Academy has also joined our organisation after his training. He is a member of the Impezar family and wish to share his skills in design and development of software systems which is being developed at Impezar. We wish to grow together with his hardwork, dedication and determination."
                         link="#" less-str="read less" :max-chars="200"></read-more>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from 'vue-avatar'

export default {
  components: {
    Avatar
  },
  name: 'TeamDetail',
  props: {
    teamArray: {type: Array, required: false, default: () => []}
  }
};

</script>
<style>
div.text-justify p{
  margin-bottom: 0px !important;
}
</style>
