<template>
  <vue-ellipse-progress
      :angle="-90"
      :color="gradient"
      :emptyColor="gradient"
      :emptyColorFill="innerColor"
      :gap="2"
      :half="false"
      :legend="true"
      :legendFormatter="({currentValue}) => new Intl.NumberFormat('en-GB').format(currentValue)"
      :legendValue="currentValue"
      :loading="false"
      :noData="false"
      :progress="currentProgress"
      :size="150"
      :thickness="2"
      animation="default 2000 1000 "
      class="progress-individual-margin m-2"
      dash="strict 200 0.5"
      dot="1 red"
      emptyThickness="10%"
      fontColor="black"
      fontSize="1rem"
      lineMode="out 3"
  >
    <span slot="legend-value"><small> {{ legendSub }}</small></span>
    <p slot="legend-caption" class="legent-note"><small>{{ legendNote }}</small></p>
  </vue-ellipse-progress>


</template>
<script>

export default {
  name: 'ValueCounter',
  props: {
    legendSub: {type: String, required: false, default: ""},
    legendNote: {type: String, required: false, default: ""},
    totalValue: {type: Number, required: true, default: 100},
    currentValue: {type: Number, required: true, default: 0},
    emptyColor: {type: String, required: false, default: "#ffffff"},
  },
  components: {},
  data() {
    return {
      gradient: {

        radial: false,
        colors: [
          {
            color: 'red',
            offset: "10",
            opacity: '1',
          },
          {
            color: 'green',
            offset: "60",
            opacity: '1',
          },
        ]
      },
      innerColor: {
        radial: true,
        colors: [
          {
            color: this.emptyColor,
            offset: "50",
            opacity: "0.15",
          },
          {
            color: this.emptyColor,
            offset: "60",
            opacity: "0.10",
          },
          {
            color: this.emptyColor,
            offset: "30",
            opacity: "0.20",
          },
          {
            color: this.emptyColor,
            offset: "40",
            opacity: "0.18",
          },
          {
            color: this.emptyColor,
            offset: "70",
            opacity: "0.05",
          }, {
            color: this.emptyColor,
            offset: "80",
            opacity: "0.03",
          },
          {
            color: this.emptyColor,
            offset: "90",
            opacity: "0.0 ",
          },
          {
            color: this.emptyColor,
            offset: "90",
            opacity: "0.0",
          },
          {
            color: this.emptyColor,
            offset: "95",
            opacity: "0.0",
          },
          {
            color: this.emptyColor,
            offset: "95",
            opacity: "0.0",
          },

        ],
      },

    };
  }, computed: {
    currentProgress() {
      return this.currentValue && this.totalValue ? (this.currentValue / this.totalValue) * 100 : 0;
    }
  }, watch: {
    emptyColor(val) {
      this.innerColor = {
        radial: true,
        colors: [
          {
            color: val,
            offset: "50",
            opacity: "0.15",
          },
          {
            color: val,
            offset: "60",
            opacity: "0.10",
          },
          {
            color: val,
            offset: "30",
            opacity: "0.20",
          },
          {
            color: val,
            offset: "40",
            opacity: "0.18",
          },
          {
            color: val,
            offset: "70",
            opacity: "0.05",
          }, {
            color: val,
            offset: "80",
            opacity: "0.03",
          },
          {
            color: val,
            offset: "90",
            opacity: "0.0 ",
          },
          {
            color: val,
            offset: "90",
            opacity: "0.0",
          },
          {
            color: val,
            offset: "95",
            opacity: "0.0",
          },
          {
            color: val,
            offset: "95",
            opacity: "0.0",
          },

        ],
      };
    }
  }
};

</script>
