<template>
  <div>
    <div class="page-header clear-filter" filter-color="primary">
      <parallax
          class="page-header-image">
      </parallax>
      <div class="container">
        <div class="content-center brand name-margin">
          <h1 class="h1-seo">{{ selectedPlan.fullName }}</h1>
          <h5 class="pt-2 text-justify">{{ selectedPlan.quickNote }}</h5>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <training-details :selectedPlan="selectedPlan"></training-details>
        </div>
      </div>
      <team-detail :team-array="selectedPlan.details.teams"  class="component-padding"></team-detail>
      <plan-confirmation  class="component-padding"></plan-confirmation>
    </div>

  </div>
</template>
<script>
import TrainingDetails from './TrainingDetails';
import TeamDetail from './TeamDetail';
import PlanConfirmation from './PlanConfirmation';

export default {
  name: 'planDetail',
  components: {
    TrainingDetails,
    TeamDetail,
    PlanConfirmation,


  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: '',
        medium: {}
      }
    };
  }, computed: {
    selectedPlan() {
      return this.$store.state.selectedPlan;
    }
  },
  mounted() {
    if (!this.selectedPlan || !this.selectedPlan.id) {
      this.$router.push('/')
    }
  }
};

</script>
<style>
.name-margin {
  margin-top: 240px;
}

.progress-space {
  margin-top: 30px;
  padding-top: 30px;
  padding-right: 10px;
}
</style>
