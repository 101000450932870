<template>
  <div class="container">
    <h3 class="title text-center">TRAINING PLANS</h3>
    <carousel :dots="false"
              :responsive="{0:{items:1,nav:false,dots:true},768:{items:2,nav:false,dots:true},992:{items:3,nav:false}}"
              class="carousel-control d-flex  align-items-center">
      <template v-if="plans.length>3" slot="prev"><span class="arrow left  d-none d-lg-block">Up</span></template>
      <div v-for="plan in plans" :key="plan.id" class="item">
        <plan-item :plan="plan"></plan-item>
      </div>
      <template v-if="plans.length>3" slot="next"><span class="arrow right d-none d-lg-block">Down</span></template>
    </carousel>
    <div class="text-center mt-3"><small class="font-weight-bolder text-primary">
      Note: Swipe to view more plans. Click on the plan for detailed information.
    </small></div>
  </div>
</template>
<script>
import Carousel from 'vue-owl-carousel'
import PlanItem from './Plan'

export default {

  components: {
    Carousel, PlanItem
  },
  computed: {
    plans() {
      return this.$store.getters.plans
    }
  }, mounted() {
    this.$store.dispatch('updateSelectedPlan', {})
  }
}
</script>


<style>
@-webkit-keyframes leftarrow {
  0% {
    -webkit-transform: translateX(0);
    opacity: 0.4
  }
  100% {
    -webkit-transform: translateX(-0.4em);
    opacity: 0.9
  }
}

@-webkit-keyframes rightarrow {
  0% {
    -webkit-transform: translateX(0);
    opacity: 0.4
  }
  100% {
    -webkit-transform: translateX(0.4em);
    opacity: 0.9
  }
}

.arrow {
  border-color: transparent;
  border-style: solid;
  border-width: 2em 0em;
  display: block;
  height: 10px;
  margin: 10em auto;
  opacity: 0.4;
  text-indent: -9999px;
  transform-origin: 50% 50%;
  width: 0;
}

.left {
  -webkit-animation: leftarrow 0.6s infinite alternate ease-in-out;
  border-right: 2em solid #05ac9e;
}

.right {
  -webkit-animation: rightarrow 0.6s infinite alternate ease-in-out;
  border-left: 2em solid #05ac9e;
}
</style>
