<template>
  <div class="w-100 h-100 rounded moveRight text-white">
    <div class="typography-line px-2 carousel-control">
      <div class="row d-flex justify-content-end  align-items-center fullwidth" @click="selectPlan">
        <div class="col-6 movingl  d-flex justify-content-end  align-items-center"><div v-html="plan.shortName[0]"></div></div>
        <div class="col-6 movingr  d-flex  align-items-center"><div v-html="plan.shortName[1]"></div></div>
        <div class="position-absolute p-4 button  h-100 d-flex justify-content-center 
         align-items-center flex-column text-justify">
          <div>{{ plan.quickNote }}</div>
          <div class="text-center pt-3">Read More</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    plan: {
      type: Object,
      required: true
    }
  }, methods: {
    selectPlan() {
      this.$store.dispatch('updateSelectedPlan', this.plan)
      this.$router.push('/detail')
    }
  }
}
</script>
<style>
.movingr {
  -moz-transition: 1s ease;
  -webkit-transition: 1s ease;
  -o-transition: 1s ease;
  transition: 1s ease;
  font-size: 70px;
}

.movingl {
  -moz-transition: 1s ease;
  -webkit-transition: 1s ease;
  -o-transition: 1s ease;
  transition: 1s ease;
  font-size: 70px;
}

.fullwidth:hover .movingr {
  left: 0%;
  top: 0%;
  opacity: 0;
  transform: translate(66%, 153%)
}

.fullwidth:hover .movingl {
  left: 0%;
  top: 0%;
  opacity: 0;
  transform: translate(-78%, -151%)
}

.fullwidth {
  position: sticky;
  height: 400px;
  width: 100%;
  background: #34968e;
  border-radius: 10px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.button {
  opacity: 0;
  transition: 1s;
  border-radius: 10px;
  cursor: pointer;
}

.button:hover {
  opacity: 1 !important;
  background: linear-gradient(#05ac9e 0%, #000 100%)
}

.moveRight {
  padding-left: 12%;
}
.typography-line{
  margin-bottom: 0px !important;
}

</style>