<template>
  <navbar
      :color-on-scroll="colorOnScroll"
      :transparent="transparent"
      menu-classes="ml-auto"
      position="fixed"
      type="primary"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <div class="d-flex align-items-center">
          <div>
            <img alt="Academy By Impezar" style="height: 29px" class="n-logo" src="img/academy.png"/>
          </div>
          <div>
            <div><h5  class="mb-0 ml-2 font-weight-bolder">Academy</h5></div>
            <div><h6 class="mb-0 font-weight-lighter text-right text-capitalize">By Impezar</h6></div>
          </div>
        </div>
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a class="nav-link" href="./#plan_pack"
           data-placement="bottom"
           rel="tooltip"
           title="Impezar Training Plans">
          <i class="now-ui-icons education_paper"></i>
          <p>Our Trainings</p>
        </a>
      </li>

      <li class="nav-item">
        <a
            class="nav-link"
            data-placement="bottom"
            href="/"
            rel="tooltip"
            title="Home Page"
        >
          <i class="fas fa-home"></i>
          <p class="d-lg-none d-xl-none">Home</p>
        </a>
      </li><li class="nav-item">
        <a
            class="nav-link"
            data-placement="bottom"
            href="https://twitter.com/impezar"
            rel="tooltip"
            target="_blank"
            title="Follow us on Twitter"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            data-placement="bottom"
            href="https://www.facebook.com/ImpezarGroup"
            rel="tooltip"
            target="_blank"
            title="Like us on Facebook"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            data-placement="bottom"
            href="https://www.instagram.com/impezar/"
            rel="tooltip"
            target="_blank"
            title="Follow us on Instagram"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            data-placement="bottom"
            href='https://linkedin.com/company/impezar'
            rel="tooltip"
            target="_blank"
            title="Follow us on Linkedin"
        >
          <i class="fab fa-linkedin"></i>
          <p class="d-lg-none d-xl-none">Linkedin</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            data-placement="bottom"
            href='mailto:academy@impezar.com'
            rel="tooltip"
            target="_blank"
            title="Feed back us on email"
        >
          <i class="far fa-envelope"></i>
          <p class="d-lg-none d-xl-none">Email</p>
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            data-placement="bottom"
            href='https://wa.me/917012602668'
            rel="tooltip"
            target="_blank"
            title="Contact us on whatsapp"
        >
          <i class="fab fa-whatsapp"></i>
          <p class="d-lg-none d-xl-none">whatsapp</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import {Navbar} from '@/components';
import {Popover} from 'element-ui';

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
