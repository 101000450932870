<template>
  <footer
      :class="{ [`footer-${type}`]: type }"
      :data-background-color="backgroundColor"
      class="footer"
  >
    <div class="container d-flex justify-content-between align-items-center">
      <nav>
        <ul>
          <li>
            <a class="nav-link"
               href="https://impezar.com/"
               target="_blank"
            >
              Impezar
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }},
        <a href="https://impezar.com/#products" rel="noopener" target="_blank"
        > Academy by Impezar</a
        >.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
