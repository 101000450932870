<template>
  <div :style="{'min-height':selectedPlan.details.height?selectedPlan.details.height:'100%'}">
    <h4 class="title text-center pb-3">{{ selectedPlan.fullName }} </h4>
    <tabs :centered="true" :pills="true" type="primary">
      <tab-pane class="desc pb-2" label="Features">
        <p v-html="selectedPlan.details.features"></p>
      </tab-pane>
      <tab-pane class="desc pb-2" label="Syllabus">
        <p v-html="selectedPlan.details.syllabus"></p>

      </tab-pane>
      <tab-pane class="desc pb-2" label="Eligibility">
        <p v-html="selectedPlan.details.eligibility"></p>

      </tab-pane>
    </tabs>
  </div>
</template>
<script>
import {TabPane, Tabs} from '@/components';

export default {
  components: {
    Tabs,
    TabPane
  }, props: {
    selectedPlan: {
      type: Object,
      required: true
    }
  },
};
</script>
<style>

ul {
  list-style: none; 
}

#section > ul li::before {
  content: "\27A4"; 
  color: #50c5bb;
  margin-right: 10px;
}
</style>
