import Vue from 'vue';
import App from './App.vue';
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import Vuelidate from 'vuelidate';
import VueEllipseProgress from 'vue-ellipse-progress';
import store from './store/store'
import ReadMore from 'vue-read-more';
Vue.use(ReadMore);


Vue.use(Vuelidate)
Vue.config.productionTip = false;

Vue.use(NowUiKit);
Vue.use(VueEllipseProgress);

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');
