<template>
  <div class="row">
    <div class=" col-md-9">
      <div class="text-center">
        <h2 class="title text-center pb-3">Join us in this course</h2>
        <form v-if="submitStatus !== 'OK'">
          <fg-input
              v-model="$v.form.name.$model"
              :error="$v.form.name.$anyDirty && $v.form.name.$anyError?'true':''"
              :isSuccess="$v.form.name.$anyDirty && !$v.form.name.$anyError"
              addon-left-icon="now-ui-icons users_circle-08"
              class="input-lg "
              placeholder="Name"
          >
          </fg-input>
          <div class="text-danger pb-3"><small>
            {{ $v.form.name.$anyDirty && !$v.form.name.required ? 'Please enter your name' : '' }}
            {{ $v.form.name.$anyDirty && !$v.form.name.minLength ? 'Name must have at-least 4 characters.' : '' }}
            {{ $v.form.name.$anyDirty && !$v.form.name.maxLength ? 'Field exceeded the character limit.' : '' }}
            {{
              $v.form.name.$anyDirty && !$v.form.name.nameValidator ? 'Only alphabets are allowed in this field.' : ''
            }}
          </small></div>
          <fg-input
              v-model="$v.form.email.$model"
              :error="$v.form.email.$anyDirty && $v.form.email.$anyError?'true':''"
              :isSuccess="$v.form.email.$anyDirty && !$v.form.email.$anyError"
              addon-left-icon="now-ui-icons ui-1_email-85"
              class="input-lg"
              placeholder="Email"
          >
          </fg-input>
          <div class="text-danger pb-3"><small>
            {{ $v.form.email.$anyDirty && !$v.form.email.required ? 'Please provide your email id' : '' }}
            {{ $v.form.email.$anyDirty && !$v.form.email.email ? 'Please provide a valid email id' : '' }}
          </small></div>
          <fg-input
              v-model="$v.form.phoneNumber.$model"
              :error="$v.form.phoneNumber.$anyDirty && $v.form.phoneNumber.$anyError?'true':''"
              :isSuccess="$v.form.phoneNumber.$anyDirty && !$v.form.phoneNumber.$anyError"
              addon-left-icon="now-ui-icons tech_mobile"
              class="input-lg"
              placeholder="Phone number (10-digit)"
              :maxlength="10"
          >
          </fg-input>
          <div class="text-danger pb-3"><small>
            {{
              $v.form.phoneNumber.$anyDirty && !$v.form.phoneNumber.required ? 'Please provide your phone number' : ''
            }}
            {{
              $v.form.phoneNumber.$anyDirty && (!$v.form.phoneNumber.minLength || !$v.form.phoneNumber.maxLength) ?
                  'Please provide a valid phone number' : ''
            }}
          </small></div>
          <div class="p-3">
            <p class="category">Training Mode</p>
            <n-radio v-for="type in selectedPlan.details.options.type" :key="type.value" v-model="form.medium"
                     :disabled="!type.enabled"
                     :label="type" inline @change.native="onMediumChange">
              {{ type.label }}
            </n-radio>
            <div><small class="text-danger"> * During this pandemic we have temporarily discontinued our classroom
              sessions</small></div>

          </div>
          <div class="p-3">
            <p class="category">Preferred Language</p>
            <n-radio v-for="language in form.medium.preferredLanguage" :key="language.value"
                     v-model="form.preferredLanguage"
                     :label="language" inline>
              {{ language.label }}
            </n-radio>
          </div>
          <div class="p-3">
            <p class="category">Training Tenure</p>
            <n-radio v-for="tenure in form.medium.trainingTenure" :key="tenure.value" v-model="form.program"
                     :label="tenure" inline>
              {{ tenure.label }} Months
            </n-radio>
          </div>
          <div class="p-3 d-inline" v-if="this.form.medium.onJobTrainingTenure">
            <p class="category">On-Job Training Tenure</p>
            <div>
              <input v-model="form.ojt" :max="slider.range.max"
                     :min="slider.range.min" :step="slider.step"
                     class="w-100 b-slider" type="range">
            </div>

            <div class="pt-3 d-flex justify-content-center">
              {{ form.ojt ? ~~form.ojt : 0 }} Month{{ form.ojt > 1 ? 's' : '' }}
            </div>
          </div>


          <div class="p-3 d-inline">
            <n-checkbox v-model="form.isEquipmentRequired" @change.native="onEquipmentNeedChange">Need assistance to
              pick my learning device
            </n-checkbox>
          </div>

          <div v-if="form.isEquipmentRequired" class="p-3">
            <p class="category">Equipment Category</p>
            <n-radio v-for="equipment in form.medium.equipmentPlan" :key="equipment.value"
                     v-model="form.lplan" :label="equipment" inline>
              {{ equipment.label }}
            </n-radio>
          </div>
          <div v-else class="p-3 d-inline">
            <small>
              {{ form.medium.equipmentNote }}
            </small>
          </div>

          <div class="textarea-container">
             <textarea
                 v-model="$v.form.message.$model"
                 :class="[
              { 'has-danger-border text-danger': $v.form.message.$anyDirty && $v.form.message.$anyError },
              { 'has-success-border': $v.form.message.$anyDirty && !$v.form.message.$anyError },
               ]"
                 class="form-control"
                 cols="80"
                 name="name"
                 placeholder="Message"
                 rows="4"
             >
              </textarea>
            <div class="text-danger pb-3"><small>
              {{
                $v.form.message.$anyDirty && !$v.form.message.required ? 'Please leave us a message.' : ''
              }}
              {{
                $v.form.message.$anyDirty && !$v.form.message.minLength ? 'We are expecting at least 10 characters in your message.' : ''
              }}
              {{ $v.form.message.$anyDirty && !$v.form.message.maxLength ? 'Please leave a shorter message.' : '' }}
            </small></div>
          </div>
          <div class="text-center p-5">
            <div><small v-if="$v.$anyDirty && $v.$invalid" class="text-danger">We have identified issues in your
              entry. Please correct the errors and try again.</small>
            </div>
            <div><small v-if="submitStatus == 'SERV-ERROR'" class="text-danger">Something went wrong. Please try again
              or email us at academy@impezar.com</small></div>
            <n-button block round size="lg" type="primary" @click.prevent="submit">
              <small v-if="submitStatus === 'PENDING'"
                     class="text spinner-border text-dark float-right spinner-border-sm m-1"
                     role="status">
                <span class="sr-only"></span></small>
              <span>Enroll</span>
            </n-button>
          </div>
        </form>
        <p v-else class="typo__p text-primary font-weight-bold pb-5">Thanks for your submission!</p>
      </div>
    </div>
    <div v-if="submitStatus !== 'OK'" class="col-md-3">
      <div class="row progress-space">
        <div class="col text-center  pb-4">
          <value-counter :current-value="form.program.cost" :emptyColor="form.medium.background"
                         :total-value="highestTrainingCost" legend-note="Training"
                         legend-sub="₹"></value-counter>
          <value-counter :current-value="ojtCost" :emptyColor="form.medium.background" :total-value="maxOjtCost"
                         legend-note="OJT"
                         legend-sub="₹" v-if="this.form.medium.onJobTrainingTenure"></value-counter>
          <value-counter :current-value="form.lplan.cost" :total-value="maxEquipmentCost"
                         legend-note="Laptop"
                         legend-sub="₹"></value-counter>
          <div class="bolder-total pt-4"> ~ ₹ {{ totalValue }} K</div>
          <small>Please note that all the costs shown above is an approximate figure. Actual cost may vary depends upon
            the availability/ stock. All the figures are in INR.</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {email, helpers, maxLength, minLength, numeric, required} from 'vuelidate/lib/validators'
import {Button, Checkbox, FormGroupInput, Radio} from '@/components';
import ValueCounter from './ValueCounter';

import axios from "axios";

const client = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});
const nameValidator = helpers.regex('name', /^[a-zA-Z ]*$/)

export default {
  name: 'planDetail',
  components: {
    [Button.name]: Button,
    [Radio.name]: Radio,
    [FormGroupInput.name]: FormGroupInput,
    [Checkbox.name]: Checkbox,
    ValueCounter,

  },
  data() {
    return {
      submitStatus: null,
      form: {
        name: '',
        phoneNumber: '',
        email: '',
        message: '',
        medium: {},
        program: {},
        preferredLanguage: {},
        ojt: 0,
        lplan: {},
        isEquipmentRequired: false
      },
      slider: {
        range: {
          min: 0,
          max: 12
        },
        step: 1
      }
    };
  }, computed: {
    selectedPlan() {
      return this.$store.state.selectedPlan;
    }, highestTrainingCost() {
      return this.form && this.form.medium && this.form.medium.trainingTenure ? Math.max.apply(Math, this.form.medium.trainingTenure.map(function (o) {
        return o.cost;
      })) : 0
    }, ojtCost() {
      return this.form && this.form.ojt && this.form.medium && this.form.medium.onJobTrainingTenure ? (this.form.ojt / this.form.medium.onJobTrainingTenure.step) * this.form.medium.onJobTrainingTenure.stepCost : 0;
    }, maxOjtCost() {
      return this.form && this.form.medium && this.form.medium.onJobTrainingTenure ? (this.form.medium.onJobTrainingTenure.maxTenure / this.form.medium.onJobTrainingTenure.step) * this.form.medium.onJobTrainingTenure.stepCost : 0
    }, maxEquipmentCost() {
      return this.form && this.form.medium && this.form.medium.equipmentPlan ? Math.max.apply(Math, this.form.medium.equipmentPlan.map(function (o) {
        return o.cost
      })) : 0
    },
    totalValue() {
      return Math.round((this.form.program.cost + this.ojtCost + (this.form.lplan && this.form.lplan.cost ? this.form.lplan.cost : 0)) / 1000);
    },
  },
  methods: {
    onMediumChange() {
      this.form.isEquipmentRequired = this.form.medium.isEquipmentNeeded;
      this.form.program = this.form.medium.trainingTenure[0];
      this.form.preferredLanguage = this.form.medium.preferredLanguage[0]
      if (this.form.medium.onJobTrainingTenure) {
        this.slider.range.min = this.form.medium.onJobTrainingTenure.minTenure;
        this.slider.range.max = this.form.medium.onJobTrainingTenure.maxTenure;
        this.slider.step = this.form.medium.onJobTrainingTenure.step;
        this.form.ojt = this.form.medium.onJobTrainingTenure.minTenure;
      }
      this.onEquipmentNeedChange();
    }, onEquipmentNeedChange() {
      if (this.form.isEquipmentRequired) {
        this.form.lplan = this.form.medium.equipmentPlan[0]
      } else {
        this.form.lplan = {}
      }
    }, submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'VAL-ERROR'
      } else {
        this.submitStatus = 'PENDING'
        client.post("/email/confirm", {
          name: this.form.name,
          email: this.form.email,
          phoneNumber: this.form.phoneNumber,
          message: this.form.message,
          medium: this.form.medium.label,
          preferredLanguage: this.form.preferredLanguage.label,
          trainingTenure: this.form.program.label,
          equipmentNeeded: this.form.isEquipmentRequired,
          ojtTenure: this.form.ojt ? ~~this.form.ojt : 0,
          equipment: this.form.lplan.label?this.form.lplan.label:'None',
          trainingCost: this.form.program.cost,
          ojtCost: this.ojtCost ? this.ojtCost : 0,
          laptopCost: this.form.lplan.cost ? this.form.lplan.cost : 0,
          plan: this.selectedPlan.fullName
        }).then(response => {
          this.submitStatus = 'OK'
        }).catch(error => {
          this.submitStatus = 'SERV-ERROR'
        })
      }
    }
  },
  mounted() {
    if (this.$store.state.selectedPlan && this.$store.state.selectedPlan.details) {
      this.form.medium = this.$store.state.selectedPlan.details.options.type[0];
      this.onMediumChange();
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(50),
        nameValidator
      },
      email: {
        required,
        email,
      },
      phoneNumber: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      message: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1000),
      }
    }
  }
};

</script>
<style>
.bolder-total {
  font-size: 150%;
  font-weight: 1000;
}

.progress-space {
  margin-top: 30px;
  padding-top: 30px;
  padding-right: 10px;
}
</style>
