<template>
  <div class="section section-signup text-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
          <div header-classes="text-center">
            <h3 class="title title-up">Contact us</h3>
            <form v-if="submitStatus !== 'OK'">
              <fg-input
                  v-model="$v.form.name.$model"
                  :error="$v.form.name.$anyDirty && $v.form.name.$anyError?'true':''"
                  :isSuccess="$v.form.name.$anyDirty && !$v.form.name.$anyError"
                  addon-left-icon="now-ui-icons users_circle-08"
                  class="input-lg "
                  placeholder="Name"
              >
              </fg-input>
              <div class="text-danger pb-3"><small>
                {{ $v.form.name.$anyDirty && !$v.form.name.required ? 'Please enter your name' : '' }}
                {{ $v.form.name.$anyDirty && !$v.form.name.minLength ? 'Name must have at-least 4 characters' : '' }}
                {{ $v.form.name.$anyDirty && !$v.form.name.maxLength ? 'Field exceeded the character limit' : '' }}
                {{
                  $v.form.name.$anyDirty && !$v.form.name.nameValidator ? 'Only alphabets are allowed in this field' : ''
                }}
              </small></div>
              <fg-input
                  v-model="$v.form.email.$model"
                  :error="$v.form.email.$anyDirty && $v.form.email.$anyError?'true':''"
                  :isSuccess="$v.form.email.$anyDirty && !$v.form.email.$anyError"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                  class="input-lg"
                  placeholder="Email"
              >
              </fg-input>
              <div class="text-danger pb-3"><small>
                {{ $v.form.email.$anyDirty && !$v.form.email.required ? 'Please provide your email id' : '' }}
                {{ $v.form.email.$anyDirty && !$v.form.email.email ? 'Please provide a valid email id' : '' }}
              </small></div>
              <fg-input
                  v-model="$v.form.phoneNumber.$model"
                  :error="$v.form.phoneNumber.$anyDirty && $v.form.phoneNumber.$anyError?'true':''"
                  :isSuccess="$v.form.phoneNumber.$anyDirty && !$v.form.phoneNumber.$anyError"
                  addon-left-icon="now-ui-icons tech_mobile"
                  class="input-lg"
                  placeholder="Phone number (10-digit)"
                  :maxlength="10"
              >
              </fg-input>
              <div class="text-danger pb-3"><small>
                {{
                  $v.form.phoneNumber.$anyDirty && !$v.form.phoneNumber.required ? 'Please provide your phone number' : ''
                }}
                {{
                  $v.form.phoneNumber.$anyDirty && (!$v.form.phoneNumber.minLength || !$v.form.phoneNumber.maxLength)
                      ? 'Please provide a valid phone number' : ''
                }}
              </small></div>
              <div class="textarea-container">
              <textarea
                  v-model="$v.form.message.$model"
                  :class="[
              { 'has-danger-border text-danger': $v.form.message.$anyDirty && $v.form.message.$anyError },
              { 'has-success-border': $v.form.message.$anyDirty && !$v.form.message.$anyError },
               ]"
                  class="form-control"
                  cols="80"
                  name="name"
                  placeholder="Message"
                  rows="4"
              >
              </textarea>
                <div class="text-danger pb-3"><small>
                  {{
                    $v.form.message.$anyDirty && !$v.form.message.required ? 'Please leave us a message.' : ''
                  }}
                  {{
                    $v.form.message.$anyDirty && !$v.form.message.minLength ? 'We are expecting at least 10 characters in your message.' : ''
                  }}
                  {{ $v.form.message.$anyDirty && !$v.form.message.maxLength ? 'Please leave a shorter message.' : '' }}
                </small></div>
              </div>

              <div class=" text-center p-5">
                <div><small v-if="$v.$anyDirty && $v.$invalid" class="text-danger">We have identified issues in your
                  entry. Please correct the errors and try again.</small></div>
                <div><small v-if="submitStatus == 'SERV-ERROR'" class="text-danger">Something went wrong. Please try
                  again or email us at academy@impezar.com</small></div>
                <n-button round size="lg" type="primary" @click.prevent="submit">
                  <small v-if="submitStatus === 'PENDING'"
                         class="text spinner-border text-dark float-right spinner-border-sm m-1"
                         role="status">
                    <span class="sr-only"></span></small>
                  <span>Submit</span>
                </n-button>

              </div>
            </form>
            <p v-else class="typo__p text-primary font-weight-bold pb-5">Thanks for your submission!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Button, FormGroupInput} from '@/components';
import {email, helpers, maxLength, minLength, numeric, required} from 'vuelidate/lib/validators'

import axios from "axios";

const client = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});
const nameValidator = helpers.regex('name', /^[a-zA-Z ]*$/)

export default {
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  }, data() {
    return {
      submitStatus: null,
      form: {
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
      }
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(50),
        nameValidator
      },
      email: {
        required,
        email,
      },
      phoneNumber: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      message: {
        required,
        minLength: minLength(10),
        maxLength:maxLength(1000)
      }
    }
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'VAL-ERROR'
      } else {
        this.submitStatus = 'PENDING'
        client.post("/email/contact", {
          name: this.form.name,
          email: this.form.email,
          phoneNumber: this.form.phoneNumber,
          message: this.form.message
        }).then(response => {
          this.submitStatus = 'OK'
        }).catch(error => {
          this.submitStatus = 'SERV-ERROR'
        })
      }
    }
  }
};
</script>
<style>
</style>
