import Vuex from 'vuex'
import Vue from 'vue'
import jss from './data/juniorDeveloper.json'
import fsd from './data/fullstackDeveloper.json'
import bed from './data/backendDeveloper.json'
import fed from './data/frontendDeveloper.json'
import st from './data/softwareTesting.json'
import de from './data/devopsEngineer.json'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        selectedPlan: {}
    },

    getters: {
        plans: state => {
            let plans = [];
            plans.push(de);
            plans.push(jss);
            plans.push(fsd);
            plans.push(bed);
            plans.push(fed);
            plans.push(st);
            return plans;
        }
    },

    mutations: {},

    actions: {
        updateSelectedPlan(context, plan) {
            context.state.selectedPlan = plan
        }
    }
});
